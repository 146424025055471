import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-individual',
  templateUrl: './register-individual.component.html',
  styleUrls: ['./register-individual.component.scss']
})
export class RegisterIndividualComponent implements OnInit {
  user: any;

  stores: any;
  branches: any;
  reps: any;
  locations:any;

  filteredBranches: any;

  formErrors: any;

  constructor(private http: HttpClient, private router: Router) {
  	this.user = {
  		name: "",
  		surname: "",
  		cellphone: "",
  		store_id: "1",
  		branch_id: "",
  		location: "",
		fnb_rep_id: ""
  	};

  	this.filteredBranches = [];

  	this.formErrors = {};

	this.locations = [
		  'Western Cape',
		  'Gauteng',
		  'Eastern Cape',
		  'Northern Cape',
		  'Kwazulu Natal',
		  'North West Province',
		  'Freestate',
		  'Limpopo',
		  'Mpumalanga'
	];

  	this.loadStores();
  	this.loadBranches();
  	this.loadReps();
  }

  loadStores() {
  	this.stores = [];

  	this.http.get("https://mm-exide.gullanandgullan.com/api/v1/stores").subscribe((data: any) => {
      console.log("DONE", data);

      this.stores = this.cleanApiData(data.data);
    },
    (err:any) => {
      console.log("ERR", err);
      Swal.fire('Oops...', 'Something went wrong. Please try again.', 'error');
    });
  }

  loadBranches() {
  	this.branches = [];

  	this.http.get("https://mm-exide.gullanandgullan.com/api/v1/branches").subscribe((data: any) => {
      console.log("DONE", data);

      this.branches = this.cleanApiData(data.data);
      
      this.filterBranches();
    },
    err => {
      console.log("ERR", err);
      Swal.fire('Oops...', 'Something went wrong. Please try again.', 'error');
    });
  }

  filterBranches() {
  	this.filteredBranches = [];

  	for (let fb of this.branches) {
  		if (parseInt(this.user.store_id) === parseInt(fb.store_id)) {
  			console.log("fb", fb.store_id);
  			this.filteredBranches.push(fb);
  		}
  	}
  }

  loadReps() {
  	this.reps = [];

  	this.http.get("https://mm-exide.gullanandgullan.com/api/v1/reps").subscribe((data: any) => {
      console.log("DONE", data);

      this.reps = this.cleanApiData(data.data);
    },
    err => {
      console.log("ERR", err);
      Swal.fire('Oops...', 'Something went wrong. Please try again.', 'error');
    });
  }

  cleanApiData(data:any) {
  	let newData = [];

  	for (let d in data) {
  		newData.push(data[d]);
  	}

  	return newData;
  }

  registerUser() {
  	let errors = false
  	this.formErrors = {};

  	if (this.user.name.length < 1) {
  		this.formErrors['name'] = 'Please enter your name';
  		errors = true;
  	}

  	if (this.user.surname.length < 1) {
  		this.formErrors['surname'] = 'Please enter your surname';
  		errors = true;
  	}

  	if (this.user.cellphone.length < 1) {
  		this.formErrors['cellphone'] = 'Please enter your mobile number';
  		errors = true;
  	}

  	if (this.user.store_id.length < 1) {
  		this.formErrors['store_id'] = 'Please select a store';
  		errors = true;
  	}

  	if (this.user.branch_id.length < 1) {
  		this.formErrors['branch_id'] = 'Please select a branch';
  		errors = true;
  	}
    
    if (this.user.location.length < 1) {
  		this.formErrors['location'] = 'Please select a region';
  		errors = true;
  	}

  	/*if (this.user.fnb_rep_id.length < 1) {
  		this.formErrors['fnb_rep_id'] = 'Please select a rep';
  		errors = true;
  	}*/

  	if (!errors) {
	  	let payload = [this.user];

	  	this.http.post("https://mm-exide.gullanandgullan.com/api/v1/register", payload).subscribe((data: any) => {
	      console.log("DONE", data);
	      Swal.fire("Success", "Thank you for registering.", 'success');
        this.goBack();
	    },
	    err => {
	      console.log("ERR", err);
        
        let errmsgs = [];
        for (let em in err.error.data) {
          for (let mm in err.error.data[em]) {
            errmsgs.push(err.error.data[em][mm]);
          }
        }

	      Swal.fire(err.error.message, errmsgs.join("<br>"), 'error');
	    });
	  }
  }
  
  goBack() {
    this.router.navigate(['welcome']);
  }

  ngOnInit(): void {
  }

}
