import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { WelcomeComponent } from './welcome/welcome.component';
import { RegisterIndividualComponent } from './register-individual/register-individual.component';
import { RegisterStoreComponent } from './register-store/register-store.component';

const routes: Routes = [
  { path: '', redirectTo: '/welcome', pathMatch: 'full' },

  { path: 'welcome', component: WelcomeComponent },
  { path: 'register/individual', component: RegisterIndividualComponent },
  { path: 'register/store', component: RegisterStoreComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }