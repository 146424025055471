<div class="register-form">
	<div class="exide-logo">
		<img src="assets/img/logo.svg" alt="Exide">
	</div>

	<div class="form-components">
		<form class="form">
      <div class="form-group welcome-text">
        <h3 style="color: rgb(255, 255, 255);" class="text-center title-no-weight mb-0">
          LEARN, EARN AND GET
        </h3>
        
        <h3 style="color: rgb(255, 255, 255);" class="text-center title-bold">
          BATTERY BUCKS
        </h3>
      </div>
      
      <div class="form-actions welcome-buttons">
        <button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" (click)="registerInd()">Register as Individual</button>
            
        <button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" (click)="registerStore()">Register as Store Manager</button>
      </div>
    </form>

    <div class="footer-text">
      <p>
        &nbsp;
      </p>

      <p>
        ©2021 Exide. All rights reserved
      </p>
    </div>
	</div>
</div>