import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-store',
  templateUrl: './register-store.component.html',
  styleUrls: ['./register-store.component.scss']
})
export class RegisterStoreComponent implements OnInit {
  user: any;

  stores: any;
  branches: any;
  reps: any;

  filteredBranches: any;

  formErrors: any;

  membersForm:any;
  memberCount: any;
  locations:any;


  constructor(private http: HttpClient, private router: Router) {
  	this.user = {
  		"store-manager": {
	  		name: "",
	  		surname: "",
	  		cellphone: "",
	  		email: "",
	  		store_id: "1",
	  		branch_id: "",
			location: "",
	  		fnb_rep_id: ""
	  	},
	  	"store-members": []
  	};

  	this.filteredBranches = [];

  	this.formErrors = {};

	  this.locations = [
		  'Western Cape',
		  'Gauteng',
		  'Eastern Cape',
		  'Northern Cape',
		  'Kwazulu Natal',
		  'North West Province',
		  'Freestate',
		  'Limpopo',
		  'Mpumalanga'
	  ];

  	this.membersForm = [];
  	this.memberCount = 0;



  	this.loadStores();
  	this.loadBranches();
  	this.loadReps();
  }

  loadStores() {
  	this.stores = [];

  	this.http.get("https://mm-exide.gullanandgullan.com/api/v1/stores").subscribe((data: any) => {
      console.log("DONE", data);

      this.stores = this.cleanApiData(data.data);
    },
    (err:any) => {
      console.log("ERR", err);
      Swal.fire('Oops...', 'Something went wrong. Please try again.', 'error');
    });
  }

  loadBranches() {
  	this.branches = [];

  	this.http.get("https://mm-exide.gullanandgullan.com/api/v1/branches").subscribe((data: any) => {
      console.log("DONE", data);

      this.branches = this.cleanApiData(data.data);
      
      this.filterBranches();
    },
    err => {
      console.log("ERR", err);
      Swal.fire('Oops...', 'Something went wrong. Please try again.', 'error');
    });
  }

  filterBranches() {
  	this.filteredBranches = [];

  	for (let fb of this.branches) {
  		if (parseInt(this.user["store-manager"].store_id) === parseInt(fb.store_id)) {
  			console.log("fb", fb.store_id);
  			this.filteredBranches.push(fb);
  		}
  	}
  }

  loadReps() {
  	this.reps = [];

  	this.http.get("https://mm-exide.gullanandgullan.com/api/v1/reps").subscribe((data: any) => {
      console.log("DONE", data);

      this.reps = this.cleanApiData(data.data);
    },
    err => {
      console.log("ERR", err);
      Swal.fire('Oops...', 'Something went wrong. Please try again.', 'error');
    });
  }

  cleanApiData(data:any) {
  	let newData = [];

  	for (let d in data) {
  		newData.push(data[d]);
  	}

  	return newData;
  }

  addMember() {
  	let memberForm = {
  		fields: [
	  		{
	  			key: "name",
	  			type: "text",
	  			label: "Member Name",
	  			placeholder: "Enter Member Name"
	  		},
	  		{
	  			key: "surname",
	  			type: "text",
	  			label: "Member Surname",
	  			placeholder: "Enter Member Surname"
	  		},
	  		{
	  			key: "cellphone",
	  			type: "text",
	  			label: "Member Mobile No.",
	  			placeholder: "Enter Member Mobile No."
	  		}
	  	],
	  	memberCount: this.memberCount
  	};

  	this.membersForm.push(memberForm);
  	this.user["store-members"][this.memberCount] = {
  		"name": "",
  		"surname": "",
  		"cellphone": ""
  	};

  	this.memberCount++;
  }

  registerUser() {
  	let errors = false
  	this.formErrors = {};

  	if (this.user["store-manager"].name.length < 1) {
  		this.formErrors['name'] = 'Please enter your name';
  		errors = true;
  	}

  	if (this.user["store-manager"].surname.length < 1) {
  		this.formErrors['surname'] = 'Please enter your surname';
  		errors = true;
  	}

  	if (this.user["store-manager"].cellphone.length < 1) {
  		this.formErrors['cellphone'] = 'Please enter your mobile number';
  		errors = true;
  	}

  	if (this.user["store-manager"].email.length < 1) {
  		this.formErrors['email'] = 'Please enter your email address';
  		errors = true;
  	}

  	if (this.user["store-manager"].store_id.length < 1) {
  		this.formErrors['store_id'] = 'Please select a store';
  		errors = true;
  	}

  	if (this.user["store-manager"].branch_id.length < 1) {
  		this.formErrors['branch_id'] = 'Please select a branch';
  		errors = true;
  	}
    
    if (this.user["store-manager"].location.length < 1) {
  		this.formErrors['location'] = 'Please select a region';
  		errors = true;
  	}

  	/*if (this.user["store-manager"].fnb_rep_id.length < 1) {
  		this.formErrors['fnb_rep_id'] = 'Please select a rep';
  		errors = true;
  	}*/

  	for (let mf in this.membersForm) {
  		if (this.user["store-members"][mf].name < 1) {
  			this.formErrors['name_'+ mf] = 'Please enter a member name';
  			errors = true;
  		}

  		if (this.user["store-members"][mf].surname < 1) {
  			this.formErrors['surname_'+ mf] = 'Please enter a member surname';
  			errors = true;
  		}

  		if (this.user["store-members"][mf].cellphone < 1) {
  			this.formErrors['cellphone_'+ mf] = 'Please enter a member mobile number';
  			errors = true;
  		}
  	}

  	if (!errors) {
	  	let payload = this.user;

	  	this.http.post("https://mm-exide.gullanandgullan.com/api/v1/store-member", payload).subscribe((data: any) => {
	      console.log("DONE", data);
	      Swal.fire("Success", "Thank you for registering as a store manager.", 'success');
        this.goBack();
	    },
	    err => {
	      console.log("ERR", err);
        
        let errmsgs = [];
        for (let em in err.error.data) {
          for (let mm in err.error.data[em]) {
            errmsgs.push(err.error.data[em][mm]);
          }
        }

	      Swal.fire(err.error.message, errmsgs.join("<br>"), 'error');
	    });
	  }
  }
  
  goBack() {
    this.router.navigate(['welcome']);
  }

  ngOnInit(): void {
  }

}
