import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { RegisterIndividualComponent } from '../register-individual/register-individual.component';
import { RegisterStoreComponent } from '../register-store/register-store.component'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }
  
  registerInd() {
    this.router.navigate(['register/individual']);
  }
  
  registerStore() {
    this.router.navigate(['register/store']);
  }

}
