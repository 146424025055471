<div class="register-form">
	<div class="exide-logo">
		<img src="assets/img/logo.png" alt="Exide">
	</div>

	<div class="form-components">
		<form class="form">
            <div class="form-group">
                <label for="username">Name</label>
                <input type="text" class="form-control" id="username" placeholder="Main Member Name" [(ngModel)]="user['store-manager'].name" #ctrl="ngModel" name="username">

                <div class="form-error" *ngIf="formErrors['name']">
                	<p>
                		{{ formErrors['name'] }}
                	</p>
                </div>
            </div>
            
            <div class="form-group">
                <label for="surname">Surname</label>
                <input type="text" class="form-control" id="surname" placeholder="Main Member Surname" [(ngModel)]="user['store-manager'].surname" #ctrl="ngModel" name="surname">

                <div class="form-error" *ngIf="formErrors['surname']">
                	<p>
                		{{ formErrors['surname'] }}
                	</p>
                </div>
            </div>

             <div class="form-group">
                <label for="mobile">Mobile No.</label>
                <input type="text" class="form-control" id="mobile" placeholder="Main Member Mobile No." [(ngModel)]="user['store-manager'].cellphone" #ctrl="ngModel" name="mobile">

                <div class="form-error" *ngIf="formErrors['cellphone']">
                	<p>
                		{{ formErrors['cellphone'] }}
                	</p>
                </div>
            </div>

            <div class="form-group">
                <label for="email">Email Address</label>
                <input type="text" class="form-control" id="email" placeholder="Main Member Email Address" [(ngModel)]="user['store-manager'].email" #ctrl="ngModel" name="email">

                <div class="form-error" *ngIf="formErrors['email']">
                	<p>
                		{{ formErrors['email'] }}
                	</p>
                </div>
            </div>

            <!--<div class="form-group">
                <label for="store">Select Store</label>
                <select class="form-control" id="store" [(ngModel)]="user['store-manager'].store_id" #ctrl="ngModel" name="store" (change)="filterBranches()">
                	<option value="">Select Store</option>
                	<option *ngFor="let store of stores" value="{{store.id}}">{{store.name}}</option>
                </select>

                <div class="form-error" *ngIf="formErrors['store_id']">
                	<p>
                		{{ formErrors['store_id'] }}
                	</p>
                </div>
            </div>-->

			<div class="form-group">
				<label for="location">Select Region</label>
				<select class="form-control" id="location" [(ngModel)]="user['store-manager'].location" #ctrl="ngModel" name="location">
					<option value="">Select Region</option>

					<option *ngFor="let location of locations" value="{{location}}">{{location}}</option>
				</select>

				<div class="form-error" *ngIf="formErrors['location']">
					<p>
						{{ formErrors['location'] }}
					</p>
				</div>
			</div>

            <div class="form-group" *ngIf="user['store-manager'].store_id > 0">
                <label for="branch">Select Branch</label>
                <select class="form-control" id="branch" [(ngModel)]="user['store-manager'].branch_id" #ctrl="ngModel" name="branch">
                	<option value="">Select Branch</option>
                	<option *ngFor="let branch of filteredBranches" value="{{branch.id}}">{{branch.display_name}}</option>
                </select>

                <div class="form-error" *ngIf="formErrors['branch_id']">
                	<p>
                		{{ formErrors['branch_id'] }}
                	</p>
                </div>
            </div>

            <!--<div class="form-group" *ngIf="user.store_id > 0">-->
                <!--<label for="branch">Select Branch</label>-->
                <!--<select class="form-control" id="branch" [(ngModel)]="user['store-manager'].branch_id" #ctrl="ngModel" name="branch">-->
                	<!--<option value="">Select Branch</option>-->
                	<!--<option *ngFor="let branch of filteredBranches" value="{{branch.id}}">{{branch.display_name}}</option>-->
                <!--</select>-->

                <!--<div class="form-error" *ngIf="formErrors['branch_id']">-->
                	<!--<p>-->
                		<!--{{ formErrors['branch_id'] }}-->
                	<!--</p>-->
                <!--</div>-->
            <!--</div>-->

            <div class="form-group">
                <label for="rep">Select FNB Rep</label>
                <select class="form-control" id="rep" [(ngModel)]="user['store-manager'].fnb_rep_id" #ctrl="ngModel" name="rep">
                	<option value="">Select FNB Rep</option>
                	<option *ngFor="let rep of reps" value="{{rep.id}}">{{rep.name}} {{rep.surname}}</option>
                </select>

                <div class="form-error" *ngIf="formErrors['fnb_rep_id']">
                	<p>
                		{{ formErrors['fnb_rep_id'] }}
                	</p>
                </div>
            </div>

            <div class="store-members" *ngIf="membersForm.length > 0">
            	<div class="member-form" *ngFor="let mf of membersForm">
            		<div class="member-form-fields" *ngFor="let mff of mf.fields">
            			<div class="form-group">
			                <label for="{{mff.key}}{{mf.memberCount}}">{{mff.label}}</label>
			                <input type="{{mff.type}}" class="form-control" id="{{mff.key}}{{mf.memberCount}}" placeholder="{{mff.placeholder}}" [(ngModel)]="user['store-members'][mf.memberCount][mff.key]" #ctrl="ngModel" name="{{mff.key}}{{mf.memberCount}}">

			                <div class="form-error" *ngIf="formErrors[mff.key +'_'+ mf.memberCount]">
			                	<p>
			                		{{ formErrors[mff.key +'_'+ mf.memberCount] }}
			                	</p>
			                </div>
			            </div>
            		</div>
            	</div>
            </div>

            <div class="form-actions">
            	<button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm btn-add-more-member" (click)="addMember()">+ Add <span *ngIf="memberCount <= 0">Counterhand</span><span *ngIf="memberCount > 0">Another Counterhand</span></button>
            	<br>
                <button class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" (click)="registerUser()">Register</button>
                
                <div class="go-back">
                	<a class="btn btn-sm btn-link" (click)="goBack()">
                		Go Back
                	</a>
            	</div>
            </div>
        </form>

        <div class="footer-text">
        	<p>
        		&nbsp;
        	</p>

        	<p>
        		©2021 Exide. All rights reserved
        	</p>
        </div>
	</div>
</div>